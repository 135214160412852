import careerpage from '../views/Careerpage.vue';
import sitemap from '../views/Sitemap.vue';
import legal from '../views/Legal.vue';
import internationallocation from '../views/Internationallocation.vue';
import international from '../views/International.vue';
import event from '../views/Event.vue';
import eventsIndex from '../views/EventsIndex.vue';
import technology from '../views/Technology.vue';
import gcp from '../views/GCP.vue';
import gcpInf from '../views/GCPinf.vue';
// import gcpMig from '../views/GCPmig.vue';
import SolutionPage from '../views/SolutionPage.vue';
import DeliveryPage from '../views/DeliveryPage.vue';
import SkillingTopic from '../views/SkillingTopic.vue';
import CourseInfo from '../views/CourseInfo.vue'

import CloudDevopsServices from '../views/CloudDevopsServices';
import customers from '../views/Customers.vue';
import CustomerPage from '../views/CustomerPage.vue';
import blog from '../views/blog.vue';
//import insights from '../views/insights.vue';
import blogs from '../views/Blogs.vue';
import podcasts from '../views/Podcasts.vue';
import support from '../views/Support.vue';
import supportPage from '../views/supportPage.vue';
import skilling from '../views/Skilling.vue';
import Delivery from '../views/Delivery.vue';
import Solutions from '../views/Solutions.vue';
import contact from '../views/Contact.vue';
import careers from '../views/Careers.vue';
import team from '../views/Team.vue';
import about from '../views/About.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import inTheNews from '../views/InTheNews.vue';
import stats from '../views/Stats.vue';
import guide from '../views/Guide.vue';
import CourseCatalog from '../views/CourseCatalog';
import BobTheBot from '../views/BobTheBot';
import Landing from '../views/Landing';
import notFound from '../views/Notfound.vue';
import NimbusDrive from '../views/static/NimbusDrive.vue';
import Ebc2025 from '../views/static/EBC2025.vue';
import { vendors } from '../../localData.json';
import USHome from '../views/us/Home.vue';
import IndiaHome from '../views/india/Home.vue';
import WhatAInight from '../views/landing/WhatAInight.vue';
import awsCXO from '../views/landing/awsCXO.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', name: 'Home', component: Home, props: true  },
  { path: '/us', name: 'USHome', component: USHome, props: { country: "us" } },
  { path: '/india', name: 'IndiaHome', component: IndiaHome, props: { country: "india" } },
  { path: '/:country?/about', name: 'About', component: about, props: true  },
  { path: '/:country?/careers', name: 'Careers', component: careers, props: true  },
  { path: '/:country?/team', name: 'Team', component: team, props: true  },
  { path: '/:country?/career/:id', name: 'CareerPage', component: careerpage, props: true  },
  { path: '/:country?/contact', name: 'Contact', component: contact, props: true  },
  { path: '/:country?/contact/thank-you', name: 'ContactThankyou', component: contact, props: true  },
  { path: '/:country?/solutions', name: 'Solutions', component: Solutions, props: true  },
  { path: '/:country?/delivery', name: 'Delivery', component: Delivery, props: true  },
  { path: '/:country?/skilling', name: 'Skilling', component: skilling, props: true  },
  { path: '/:country?/support', name: 'Support', component: support, props: true  },
  { path: '/:country?/guide', name: 'Guide', component: guide, props: true  },
  { path: '/:country?/support/:slug', name: 'SupportPage', component: supportPage, props: true  },
  { path: '/:country?/insights', name: 'insights', component: blogs, props: true },
  { path: '/:country?/podcasts', name: 'podcasts', component: podcasts, props: true },
  { path: '/:country?/insights/:id', name: 'blog', component: blog, props: true  },
  { path: '/:country?/customers', name: 'Customers', component: customers, props: true  },
  { path: '/:country?/:technology/customer-story/:slug', name: 'CustomerPage', component: CustomerPage, props: true  },
  { path: '/:country?/technology', name: 'Technology', component: technology, props: true  },
  ...vendors.map((v) => ({ path: `/:country?/${v.slug}`, name: v.name, component: gcp, props: true })),
  ...vendors
    .map((v) => v.pages.map((p) => ({ path: `/:country?/${v.slug}/${p.slug}`, name: p.name + v.name, component: gcpInf, props: true })))
    .flat(),
  // { path: '/google-cloud/infrastructure', name: 'GCP', component: gcpInf },
  // { path: '/google-cloud/cloud-migration', name: 'GCP', component: gcpMig },
  { path: '/:country?/events', name: 'Events-index', component: eventsIndex, props: true },
  { path: '/:country?/event/:id', name: 'Event', component: event, props: true },
  { path: '/:country?/event/:id/thank-you', name: 'EventThankyou', component: event, props: true },
  { path: '/:country?/international', name: 'International', component: international, props: true },
  { path: '/:country?/international/:id', name: 'InternationalLocation', component: internationallocation, props: true },
  { path: '/:country?/legal/:slug', name: 'Legal', component: legal, props: true },
  { path: '/:country?/sitemap', name: 'SiteMap', component: sitemap, props: true },
  { path: '/:country?/in-the-news', component: inTheNews, props: true },
  { path: '/:country?/stats', name: 'stats', component: stats, props: true },
  { path: '/:country?/coursecatalog', name: 'Courses', component: CourseCatalog, props: true },
  { path: '/:country?/bobthebot', name: 'BobTheBot', component: BobTheBot, props: true },
  { path: '/:country?/solutions/:slug', name: 'Solution', component: SolutionPage, props: true },
  { path: '/:country?/aws/cloud-devops', name: 'CloudDevopsServices', component: CloudDevopsServices, props: true },
  { path: '/:country?/delivery/thank-you', name: 'DeliveryThankyou', component: Delivery, props: true },
  { path: '/:country?/delivery/:slug', name: 'Delivery', component: DeliveryPage, props: true },
  { path: '/:country?/coursecatalog/:slug', name: 'Course', component: SkillingTopic, props: true },
  { path: '/:country?/coursecatalog/c/:slug', name: 'Course', component: CourseInfo, props: true },
  { path: '/:country?/graphic-maker', name: 'Graphics', component: () => import('../views/GraphicGenerator.vue'), props: true },
  { path: '/:country?/landing/:id', name: 'Landing', component: Landing, props: true },
  { path: '/:country?/what-ai-night', name: 'whatainight', component: WhatAInight, props: true },
  { path: '/:country?/aws-cxo', name: 'awscxo', component: awsCXO, props: true },
  { path: '/:country?/nimbusdrive', name: 'NimbusDrive', component: NimbusDrive, props: true },
  { path: '/:country?/ebc2025', name: 'EBC2025', component: Ebc2025, props: true },
  { path: '/:country?/*', name: '404', component: notFound, props: true },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to) {
    if (to.hash)
      return {
        behavior: 'smooth',
        selector: to.hash,
        offset: { x: 0, y: 100 },
      };
    return { y: 0 };
  },
});

export default router;
